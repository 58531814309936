import { toUpper } from 'lodash';
import { useQuery, useSession } from 'common/hooks';
import { UseQueryOptions } from 'common/types/Api.type';
import { GetOrganizationalStaticDataParams } from '../types';
import { OrganizationalStaticDataTypeEnum } from '../static-data.enum';
import { getManagedStaticDataApi } from '../services/static-data.service';

export const useGetStaticDataForProfileStaticDataUpdate = (
  params: GetOrganizationalStaticDataParams,
  options: UseQueryOptions = {},
) => {
  const { organizationIdOnSession: organizationId } = useSession();

  const { type } = params;
  const staticDataType = type && (toUpper(type) as OrganizationalStaticDataTypeEnum);

  const isValidStaticDataType =
    !!staticDataType &&
    Object.values(OrganizationalStaticDataTypeEnum).includes(staticDataType);

  const queryResponse = useQuery(
    [organizationId, 'static-data-for-profile-edit', staticDataType],
    () =>
      isValidStaticDataType &&
      getManagedStaticDataApi({
        organizationId: organizationId,
        type: staticDataType,
      }),
    {
      enabled: !!organizationId && isValidStaticDataType,
      showErrorSnackbar: true,
      ...options,
    },
  );

  return {
    ...queryResponse,
    data: queryResponse?.data?.data,
  };
};

/**
 * This feature is a super set of System features (Domain Entities) as well as the UI(view) features
 */
export enum FeaturesEnum {
  FEEDS = 'FEEDS',
  POSTS = 'POSTS',
  OTHER_POST = 'OTHER_POST',
  OPPORTUNITIES = 'OPPORTUNITIES',
  PRAISES = 'PRAISES',
  ALL_COMPANY_UPDATES = 'ALL_COMPANY_UPDATES',
  SOME_COMPANY_UPDATES = 'SOME_COMPANY_UPDATES',
  ALL_ORGANIZATION_EVENTS = 'ALL_ORGANIZATION_EVENTS',
  SOME_ORGANIZATION_EVENTS = 'SOME_ORGANIZATION_EVENTS',
  VIDEOS = 'VIDEOS',
  WINS = 'WINS',
  CREATE_WINS = 'CREATE_WINS',
  OTHER_WINS = 'OTHER_WINS',
  COMMENTS_AND_REACTIONS = 'COMMENTS_AND_REACTIONS',
  ORGANIZATION_QUICK_LINKS = 'ORGANIZATION_QUICK_LINKS',
  OWN_PROFILE_QUICK_LINKS = 'OWN_PROFILE_QUICK_LINKS',
  OTHER_PROFILE_QUICK_LINKS = 'OTHER_PROFILE_QUICK_LINKS',
  ORG_CHART = 'ORG_CHART',
  BEST_OF = 'BEST_OF',
  EXPERIENCE = 'EXPERIENCE',
  PRINCIPLES_US = 'PRINCIPLES_US',
  SKILLS = 'SKILLS',
  ASPIRATIONS = 'ASPIRATIONS',
  PROFILES = 'PROFILES',
  ORG_PAGE = 'ORG_PAGE',
  FOLLOW = 'FOLLOW',
  MANAGE_NOTIFICATIONS = 'MANAGE_NOTIFICATIONS',
  BIOGRAPHY = 'BIOGRAPHY',
  PROFILE_FEEDS = 'PROFILE_FEEDS',
  ADMIN_SETTINGS = 'ADMIN_SETTINGS',
  SOME_STATIC_DATA_PAGES = 'SOME_STATIC_DATA_PAGES',
  ALL_STATIC_DATA_PAGES = 'ALL_STATIC_DATA_PAGES',
  STATIC_DATA_PAGES_ABOUT = 'STATIC_DATA_PAGES_ABOUT',
  PAGE_COMPLETION = 'PAGE_COMPLETION',
  PROFILE_COMPLETION = 'PROFILE_COMPLETION',
  OWN_PROFILE = 'OWN_PROFILE',
  OTHER_PROFILE = 'OTHER_PROFILE',
  GROUPS = 'GROUPS',
  PAGE_ADMIN = 'PAGE_ADMIN',
  KEY_CONTACTS = 'KEY_CONTACTS',
  RELATED_PAGES = 'RELATED_PAGES',
  TAGGINGS = 'TAGGINGS',
  ONBOARDING_STEPS = 'ONBOARDING_STEPS',
  /** UI Features (Composite features) */
  SEARCH_SUGGESTION = 'SEARCH_SUGGESTION',
  USER_SETTINGS = 'USER_SETTINGS',
  CONTENT_CREATION_WIDGET = 'CONTENT_CREATION_WIDGET',
  MANAGE_STATIC_DATA = 'MANAGE_STATIC_DATA',

  ORGANIZATION_CONFIG = 'ORGANIZATION_CONFIG',
  ORGANIZATION_CREATION = 'ORGANIZATION_CREATION',
  PROFILE_EXPORT = 'PROFILE_EXPORT',
  PROTECTED_PROFILE_DATA = 'PROTECTED_PROFILE_DATA',
  OTHER_REVIEW = 'OTHER_REVIEW',
  ALL_FEED_COMMENT = 'ALL_FEED_COMMENT',
  COMPANY_PROFILE_DELETION = 'COMPANY_PROFILE_DELETION',

  DUMMY_FEATURE = 'DUMMY_FEATURE',
}

import React, { SyntheticEvent, useState } from 'react';
import { toLower } from 'lodash';

import { FormAutocomplete } from 'common/components/input';
import { OrgStaticData, OrgStaticDataSelectorProps } from 'features/static-data';
import { useDeferredState } from 'common/hooks';
import { getNoSelectorOptionsText, getString } from 'common/utils/app.utils';
import { useGetStaticDataForProfileStaticDataUpdate } from 'features/static-data/hooks/useGetStaticDataForProfileStaticDataUpdate';

const OrgStaticDataSelector: React.FC<OrgStaticDataSelectorProps> = ({
  label,
  formFieldProps,
  type,
  value = null,
  preload,
  onChange,
  ...staticDataSelectorProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const [staticData, setStaticData] = useDeferredState<OrgStaticData | null>(value);
  const [initialized, setInitialized] = useState(false);

  const selectedLabel = getString(staticData, 'fullName');
  const inputIsSelectedValue = selectedLabel === inputValue;

  const {
    data,
    isLoading: isLoadingStaticData,
    isSuccess: hasLoadedStaticData,
  } = useGetStaticDataForProfileStaticDataUpdate(
    { type },
    {
      enabled: preload || initialized,
    },
  );

  return (
    <FormAutocomplete
      autoComplete
      loading={isLoadingStaticData}
      label={label}
      getOptionLabel={(option: OrgStaticData) => {
        const fullNameLabel = getString(option, 'fullName', '');
        if (option.isVisible === false) return `${fullNameLabel} (inactive)`;
        return fullNameLabel;
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onInputChange={(e: SyntheticEvent, inputValue: string) => {
        setInputValue(inputValue);
      }}
      onChange={(value: OrgStaticData) => {
        setInputValue('');
        setStaticData(value);
        onChange && onChange(value);
      }}
      options={data?.result || []}
      filterOptions={(options = []) => {
        return inputValue && !inputIsSelectedValue
          ? options.filter((staticData: OrgStaticData) => {
              return toLower(staticData.fullName).includes(toLower(inputValue));
            })
          : options;
      }}
      noOptionsText={getNoSelectorOptionsText({
        hasData: hasLoadedStaticData,
        isLoading: isLoadingStaticData,
        label,
        preload,
      })}
      formFieldProps={formFieldProps}
      value={staticData}
      onFocus={() => {
        if (!initialized) {
          setInitialized(true);
        }
      }}
      {...staticDataSelectorProps}
    />
  );
};

export default OrgStaticDataSelector;

import { SyntheticEvent, useState } from 'react';

import { useDeferredState } from 'common/hooks';
import { OrgStaticData, MultiOrgStaticDataSelectorProps } from 'features/static-data';
import FormAutocomplete from './FormAutocomplete';
import { getNoSelectorOptionsText, getString } from 'common/utils/app.utils';
import { useGetStaticDataForProfileStaticDataUpdate } from 'features/static-data/hooks/useGetStaticDataForProfileStaticDataUpdate';

const MultiOrgStaticDataSelector: React.FC<MultiOrgStaticDataSelectorProps> = ({
  label,
  formFieldProps,
  type,
  value = [],
  preload,
  readOnly,
  onChange,
  limit,
  ...staticDataSelectorProps
}) => {
  const [inputValue, setInputValue] = useState('');
  const [staticData, setStaticData] = useDeferredState<OrgStaticData[]>(value);
  const [initialized, setInitialized] = useState(false);

  const {
    data,
    isLoading: isLoadingStaticData,
    isSuccess: hasLoadedStaticData,
  } = useGetStaticDataForProfileStaticDataUpdate(
    { type },
    {
      enabled: preload || initialized,
    },
  );

  return (
    <FormAutocomplete
      multiple
      autoComplete
      placeholder="Type to perform a search"
      loading={isLoadingStaticData}
      limit={limit}
      label={label}
      options={data?.result || []}
      getOptionLabel={(option: OrgStaticData) => getString(option, 'fullName', '')}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionAvatar={(option) => option?.photo}
      onInputChange={(e: SyntheticEvent, inputValue: string, reason: string) => {
        /**
         * This callback fired on input blur or when the
         * dropdown appeared, even with clearOnBlur set to false.
         * Had to ignore these reset events to prevent clearing
         * the input prematurely.
         */
        if (reason === 'reset') return;
        setInputValue(inputValue);
      }}
      onChange={(values: OrgStaticData[]) => {
        setInputValue('');
        setStaticData(values);
        onChange && onChange(values);
      }}
      inputValue={inputValue}
      noOptionsText={getNoSelectorOptionsText({
        hasData: hasLoadedStaticData,
        isLoading: isLoadingStaticData,
        label,
        preload,
      })}
      formFieldProps={formFieldProps}
      value={staticData}
      customReadOnly={readOnly}
      onFocus={() => {
        if (!initialized) {
          setInitialized(true);
        }
      }}
      {...staticDataSelectorProps}
    />
  );
};

export default MultiOrgStaticDataSelector;

import { useState } from 'react';

import { ProjectFormValue, WinPeriod } from '../types';
import InlineProjectForm from './inline-project-form.component';
import AddStaticDataButton from 'features/org-root/components/StaticData/AddStaticDataButton';
import { Profile } from 'features/profiles/models/profile.model';
import { Tag } from 'features/tags/types';
import { useFeatureAccess } from 'features/feature-access/hooks/useFeatureAccess';
import { FeaturesEnum } from 'features/feature-access/features.enum';

type InlineProjectProps = {
  owner?: Profile;
  tags?: Tag[];
  period?: WinPeriod;
  skipProjectCreation?: boolean;
  onSubmit?: (title: string) => void;
  autoFocusAddNewWinField?: boolean;
};

export const InlineProject: React.FC<InlineProjectProps> = ({
  tags,
  owner,
  period,
  skipProjectCreation = false,
  onSubmit,
  autoFocusAddNewWinField = false,
}) => {
  const { canWrite: canCreateWin } = useFeatureAccess(FeaturesEnum.CREATE_WINS);
  const [editMode, setEditMode] = useState(autoFocusAddNewWinField);

  const inlineProjectRequest = {
    title: '',
    description: '',
    skills: [],
    owner: owner?.id ? [owner] : owner,
    tags,
    period,
    visibleTo: [],
  } as ProjectFormValue;

  const openWinInput = () => {
    setEditMode(true);
  };

  if (editMode) {
    return (
      <InlineProjectForm
        data={inlineProjectRequest}
        skipProjectCreation={skipProjectCreation}
        onClose={() => setEditMode(false)}
        onSubmit={onSubmit}
        onOpen={openWinInput}
      />
    );
  }

  if (canCreateWin) {
    return (
      <AddStaticDataButton data-testid="inline-add-new-win" onClick={() => setEditMode(true)}>
        {' '}
        Add New
      </AddStaticDataButton>
    );
  }

  return <></>;
};
